import {
  FETCH_RIDES_REQUEST,
  FETCH_RIDES_SUCCESS,
  FETCH_RIDES_FAILURE,
  FETCH_SINGLE_RIDE_REQUEST,
  FETCH_SINGLE_RIDE_SUCCESS,
  FETCH_SINGLE_RIDE_FAILURE,
  UPDATE_SINGLE_RIDE_REQUEST,
  UPDATE_SINGLE_RIDE_SUCCESS,
  UPDATE_SINGLE_RIDE_FAILURE,
  CANCEL_RIDE_REQUEST,
  CANCEL_RIDE,
  RESEND_INVOICE,
  RESEND_INVOICE_REQUEST,
  FETCH_PAYMENTS_REQUEST,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_FAILURE,
  ADD_PAYMENT_REQUEST,
  ADD_PAYMENT_SUCCESS,
  ADD_PAYMENT_FAILURE,
  ODOMETER_READING_FAILURE,
  ODOMETER_READING_SUCCESS,
  STATE_CLEAN,
  ADD_DRIVER_FAILURE,
  ADD_DRIVER_REQUEST,
  ADD_DRIVER_SUCCESS,
  ODOMETER_READING_REQUEST,
} from "./RidesTypes";

const initialState = {
  loading: false,
  loadingride: false,
  rides: [],
  error: "",
  singleride: {},
  ridesflag: "New Bookings",
  singlerideerror: "",
  singlerideupdatemsg: "",
  loadingpayments: false,
  payments: [],
  paymentsMsg: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RIDES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RIDES_SUCCESS:
      return {
        ...state,
        loading: false,
        singleride: {},
        singlerideupdatemsg: "",
        paymentsMsg: "",
        rides: action.payload.rides || [],
        ridesflag: action.payload.ridesFlag,
        error: "",
      };
    case FETCH_RIDES_FAILURE:
      return {
        ...state,
        loading: false,
        rides: [],
        ridesflag: action.payload.ridesFlag,
        error: action.payload,
      };
    case FETCH_SINGLE_RIDE_REQUEST:
      return {
        ...state,
        singlerideupdatemsg: "",
        loadingride: true,
      };
    case FETCH_SINGLE_RIDE_SUCCESS:
      return {
        ...state,
        loadingride: false,
        singlerideupdatemsg: "",
        singleride: action.payload,
        payments: [],
        paymentsMsg: "",
      };
    case FETCH_SINGLE_RIDE_FAILURE:
      return {
        ...state,
        singlerideerror: action.payload,
      };
    case UPDATE_SINGLE_RIDE_REQUEST:
      return {
        ...state,
        singlerideupdatemsg: "",
      };
    case UPDATE_SINGLE_RIDE_SUCCESS:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };

    case UPDATE_SINGLE_RIDE_FAILURE:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case ADD_DRIVER_REQUEST:
      return {
        ...state,
        singlerideupdatemsg: "",
      };
    case ADD_DRIVER_SUCCESS:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case ADD_DRIVER_FAILURE:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case CANCEL_RIDE_REQUEST:
      return {
        ...state,
        singlerideupdatemsg: "",
      };
    case CANCEL_RIDE:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case RESEND_INVOICE_REQUEST:
      return {
        ...state,
        singlerideupdatemsg: "",
      };
    case RESEND_INVOICE:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case FETCH_PAYMENTS_REQUEST:
      return {
        ...state,
        loadingpayments: true,
      };
    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        loadingpayments: false,
        payments: action.payload || [],
        paymentsMsg: "",
      };
    case FETCH_PAYMENTS_FAILURE:
      return {
        ...state,
        loadingpayments: false,
        paymentsMsg: action.payload,
      };
    case ADD_PAYMENT_REQUEST:
      return {
        ...state,
        paymentsMsg: "",
      };
    case ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentsMsg: action.payload,
      };
    case ADD_PAYMENT_FAILURE:
      return {
        ...state,
        paymentsMsg: action.payload,
      };
    case ODOMETER_READING_REQUEST:
      return {
        ...state,
        singlerideupdatemsg: "",
      };
    case ODOMETER_READING_SUCCESS:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case ODOMETER_READING_FAILURE:
      return {
        ...state,
        singlerideupdatemsg: action.payload,
      };
    case STATE_CLEAN:
      return {
        ...state,
        paymentsMsg: "",
        singlerideupdatemsg: "",
        error: "",
        singlerideerror: "",
      };
    default:
      return state;
  }
};

export default reducer;
