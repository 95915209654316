export const FETCH_BUSINESSES_REQUEST = "FETCH_BUSINESSES_REQUEST";
export const FETCH_BUSINESSES_SUCCESS = "FETCH_BUSINESSES_SUCCESS";
export const FETCH_BUSINESSES_FAILURE = "FETCH_BUSINESSES_FAILURE";
export const FETCH_LOCATIONS_REQUEST = "FETCH_LOCATIONS_REQUEST";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAILURE = "FETCH_LOCATIONS_FAILURE";
export const FETCH_VENDOR_PAYOUTS_REQUEST = "FETCH_VENDOR_PAYOUTS_REQUEST";
export const FETCH_VENDOR_PAYOUTS_SUCCESS = "FETCH_VENDOR_PAYOUTS_SUCCESS";
export const FETCH_VENDOR_PAYOUTS_FAILURE = "FETCH_VENDOR_PAYOUTS_FAILURE";
export const FETCH_SELF_DRIVE_REFUND_REPORT_REQUEST =
  "FETCH_SELF_DRIVE_REFUND_REPORT_REQUEST";
export const FETCH_SELF_DRIVE_REFUND_REPORT_SUCCESS =
  "FETCH_SELF_DRIVE_REFUND_REPORT_SUCCESS";
export const FETCH_SELF_DRIVE_REFUND_REPORT_FAILURE =
  "FETCH_SELF_DRIVE_REFUND_REPORT_FAILURE";
export const FETCH_METRICS_REQUEST = "FETCH_METRICS_REQUEST";
export const FETCH_METRICS_SUCCESS = "FETCH_METRICS_SUCCESS";
export const FETCH_METRICS_FAILURE = "FETCH_METRICS_FAILURE";
export const ADD_VENDOR_REQUEST = "ADD_VENDOR_REQUEST";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_FAILURE = "ADD_VENDOR_FAILURE";
export const UPDATE_VENDOR_REQUEST = "UPDATE_VENDOR_REQUEST";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAILURE = "UPDATE_VENDOR_FAILURE";
export const UPDATE_SUB_CATEGORIES = "UPDATE_SUB_CATEGORIES";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";
//
export const STATE_CLEAN = "STATE_CLEAN";
