import { combineReducers } from "redux";
import AuthReducer from "./auth/AuthReducer";
import QuotesReducer from "./quotes/QuotesReducer";
import AgenciesReducer from "./agencies/AgenciesReducer";
import RidesReducer from "./rides/RidesReducer";
import SkippedOReducer from "./skippedorders/SkippedOReducer";
import VendorsReducer from "./vendors/VendorsReducer";
import SwingReducer from "./swing/SwingReducer";
import ZyppysExtendedReducer from "./zyppysextended/ZyppysExtendedReducer";

export default combineReducers({
  auth: AuthReducer,
  quotes: QuotesReducer,
  agencies: AgenciesReducer,
  skippedorders: SkippedOReducer,
  rides: RidesReducer,
  vendors: VendorsReducer,
  swing: SwingReducer,
  zyppysextended: ZyppysExtendedReducer,
});
