import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_ACTION,
} from "./AuthTypes";

var user_LS;
if (typeof localStorage !== `undefined`) {
  user_LS = JSON.parse(localStorage.getItem("user"));
  // console.log("usr from LS reducer", JSON.parse(localStorage.getItem("user")))
}
const initialState = {
  loading: false,
  user: user_LS || {},
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload,
      };
    case USER_LOGOUT_ACTION:
      return {
        ...state,
        user: {},
        loading: false,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
