import {
  FETCH_BUSINESSES_FAILURE,
  FETCH_BUSINESSES_REQUEST,
  FETCH_BUSINESSES_SUCCESS,
  FETCH_VENDOR_PAYOUTS_FAILURE,
  FETCH_VENDOR_PAYOUTS_REQUEST,
  FETCH_VENDOR_PAYOUTS_SUCCESS,
  FETCH_SELF_DRIVE_REFUND_REPORT_REQUEST,
  FETCH_SELF_DRIVE_REFUND_REPORT_SUCCESS,
  FETCH_SELF_DRIVE_REFUND_REPORT_FAILURE,
  FETCH_METRICS_REQUEST,
  FETCH_METRICS_SUCCESS,
  FETCH_METRICS_FAILURE,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_REQUEST,
  UPDATE_SUB_CATEGORIES,
  UPDATE_CATEGORIES,
} from "./VendorsTypes";

const initialState = {
  loadingbusinesses: false,
  loadinglocations: false,
  loadingpayouts: false,
  loadingrefundreport: false,
  loadingmetrics: false,
  businesses: [],
  locations: [],
  errorbusinesses: "",
  errorlocations: "",
  errorpayouts: "",
  errorrefundreport: "",
  errormetrics: "",
  payouts: [],
  refundreport: [],
  metrics: [],
  vendor: "",
  subCategories: [],
  categories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUSINESSES_REQUEST:
      return {
        ...state,
        loadingbusinesses: true,
        vendor: "",
      };
    case FETCH_BUSINESSES_SUCCESS:
      return {
        ...state,
        loadingbusinesses: false,
        businesses: action.payload,
        errorbusinesses: "",
      };
    case FETCH_BUSINESSES_FAILURE:
      return {
        ...state,
        loading: false,
        businesses: [],
        error: action.payload,
      };
    case FETCH_LOCATIONS_REQUEST:
      return {
        ...state,
        loadinglocations: true,
      };
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        loadinglocations: false,
        locations: action.payload || [],
        errorlocations: "",
      };
    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        loadinglocations: false,
        locations: [],
        errorlocations: action.payload,
      };
    case FETCH_VENDOR_PAYOUTS_REQUEST:
      return {
        ...state,
        loadingpayouts: true,
        vendor: action.payload,
      };
    case FETCH_VENDOR_PAYOUTS_SUCCESS:
      return {
        ...state,
        loadingpayouts: false,
        payouts: action.payload || [],
        errorpayouts: "",
      };
    case FETCH_VENDOR_PAYOUTS_FAILURE:
      return {
        ...state,
        loadingpayouts: false,
        payouts: [],
        errorpayouts: action.payload,
      };
    case FETCH_SELF_DRIVE_REFUND_REPORT_REQUEST:
      return {
        ...state,
        loadingrefundreport: true,
      };
    case FETCH_SELF_DRIVE_REFUND_REPORT_SUCCESS:
      return {
        ...state,
        loadingrefundreport: false,
        refundreport: action.payload || [],
        errorrefundreport: "",
      };
    case FETCH_SELF_DRIVE_REFUND_REPORT_FAILURE:
      return {
        ...state,
        loadingrefundreport: false,
        refundreport: [],
        errorrefundreport: action.payload,
      };
    case FETCH_METRICS_REQUEST:
      return {
        ...state,
        loadingmetrics: true,
      };
    case FETCH_METRICS_SUCCESS:
      return {
        ...state,
        loadingmetrics: false,
        metrics: action.payload || [],
        errormetrics: "",
      };
    case FETCH_METRICS_FAILURE:
      return {
        ...state,
        loadingmetrics: false,
        metrics: [],
        errormetrics: action.payload,
      };
    case UPDATE_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload || [],
      };
    case UPDATE_CATEGORIES:
      return {
        ...state,
        categories: action.payload || [],
      };
    default:
      return state;
  }
};

export default reducer;
