export const FETCH_AGENCIES_REQUEST = "FETCH_AGENCIES_REQUEST";
export const FETCH_AGENCIES_SUCCESS = "FETCH_AGENCIES_SUCCESS";
export const FETCH_AGENCIES_FAILURE = "FETCH_AGENCIES_FAILURE";
export const UPDATE_AGENCY_SUCCESS = "UPDATE_AGENCY_SUCCESS";
export const UPDATE_AGENCY_FAILURE = "UPDATE_AGENCY_FAILURE";
export const FETCH_VEHICLE_AVAILABILITY_REQUEST =
  "FETCH_VEHICLE_AVAILABILITY_REQUEST";
export const FETCH_VEHICLE_AVAILABILITY_SUCCESS =
  "FETCH_VEHICLE_AVAILABILITY_SUCCESS";
export const FETCH_VEHICLE_AVAILABILITY_FAILURE =
  "FETCH_VEHICLE_AVAILABILITY_FAILURE";
export const UPDATE_VEHICLE_AVAILABILITY__SUCCESS =
  "UPDATE_VEHICLE_AVAILABILITY__SUCCESS";
export const UPDATE_VEHICLE_AVAILABILITY__FAILURE =
  "UPDATE_VEHICLE_AVAILABILITY__FAILURE";
export const FETCH_PRICING_REQUEST = "FETCH_PRICING_REQUEST";
export const FETCH_PRICING_SUCCESS = "FETCH_PRICING_SUCCESS";
export const FETCH_PRICING_FAILURE = "FETCH_PRICING_FAILURE";
export const ADD_UPDATE_PRICING_REQUEST = "ADD_UPDATE_PRICING_REQUEST";
export const ADD_UPDATE_PRICING_SUCCESS = "ADD_UPDATE_PRICING_SUCCESS";
export const ADD_UPDATE_PRICING_FAILURE = "ADD_UPDATE_PRICING_FAILURE";
//
export const STATE_CLEAN = "STATE_CLEAN";
