// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-addorupdatepricing-js": () => import("./../../../src/pages/addorupdatepricing.js" /* webpackChunkName: "component---src-pages-addorupdatepricing-js" */),
  "component---src-pages-addorupdatevendor-js": () => import("./../../../src/pages/addorupdatevendor.js" /* webpackChunkName: "component---src-pages-addorupdatevendor-js" */),
  "component---src-pages-agencies-js": () => import("./../../../src/pages/agencies.js" /* webpackChunkName: "component---src-pages-agencies-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-dashboardadmin-js": () => import("./../../../src/pages/dashboardadmin.js" /* webpackChunkName: "component---src-pages-dashboardadmin-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-metrics-js": () => import("./../../../src/pages/metrics.js" /* webpackChunkName: "component---src-pages-metrics-js" */),
  "component---src-pages-notifications-js": () => import("./../../../src/pages/notifications.js" /* webpackChunkName: "component---src-pages-notifications-js" */),
  "component---src-pages-partnerwithus-js": () => import("./../../../src/pages/partnerwithus.js" /* webpackChunkName: "component---src-pages-partnerwithus-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-quotes-js": () => import("./../../../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-refundreport-js": () => import("./../../../src/pages/refundreport.js" /* webpackChunkName: "component---src-pages-refundreport-js" */),
  "component---src-pages-requestquote-js": () => import("./../../../src/pages/requestquote.js" /* webpackChunkName: "component---src-pages-requestquote-js" */),
  "component---src-pages-ride-js": () => import("./../../../src/pages/ride.js" /* webpackChunkName: "component---src-pages-ride-js" */),
  "component---src-pages-rides-js": () => import("./../../../src/pages/rides.js" /* webpackChunkName: "component---src-pages-rides-js" */),
  "component---src-pages-skippedorders-js": () => import("./../../../src/pages/skippedorders.js" /* webpackChunkName: "component---src-pages-skippedorders-js" */),
  "component---src-pages-swing-ride-js": () => import("./../../../src/pages/swingRide.js" /* webpackChunkName: "component---src-pages-swing-ride-js" */),
  "component---src-pages-swingtrips-js": () => import("./../../../src/pages/swingtrips.js" /* webpackChunkName: "component---src-pages-swingtrips-js" */),
  "component---src-pages-swinguser-js": () => import("./../../../src/pages/swinguser.js" /* webpackChunkName: "component---src-pages-swinguser-js" */),
  "component---src-pages-swingusers-js": () => import("./../../../src/pages/swingusers.js" /* webpackChunkName: "component---src-pages-swingusers-js" */),
  "component---src-pages-vehicleavailability-js": () => import("./../../../src/pages/vehicleavailability.js" /* webpackChunkName: "component---src-pages-vehicleavailability-js" */),
  "component---src-pages-vendorpayouts-js": () => import("./../../../src/pages/vendorpayouts.js" /* webpackChunkName: "component---src-pages-vendorpayouts-js" */),
  "component---src-pages-zyppysexplore-js": () => import("./../../../src/pages/zyppysexplore.js" /* webpackChunkName: "component---src-pages-zyppysexplore-js" */)
}

