import {
  FETCH_SWING_USERS_REQUEST,
  FETCH_SWING_USERS_SUCCESS,
  FETCH_SWING_USERS_FAILURE,
  FETCH_SWING_TRIPS_REQUEST,
  FETCH_SWING_TRIPS_SUCCESS,
  FETCH_SWING_TRIPS_FAILURE,
} from "./SwingTypes";

const initialState = {
  loadingswingusers: false,
  swingusers: [],
  errorswingusers: "",
  loadingswingtrips: false,
  swingtrips: [],
  errorswingtrips: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SWING_USERS_REQUEST:
      return {
        ...state,
        loadingswingusers: true,
      };
    case FETCH_SWING_USERS_SUCCESS:
      return {
        ...state,
        loadingswingusers: false,
        swingusers: action.payload || [],
        errorswingusers: "",
      };
    case FETCH_SWING_USERS_FAILURE:
      return {
        ...state,
        loadingswingusers: false,
        swingusers: [],
        errorswingusers: action.payload,
      };
    case FETCH_SWING_TRIPS_REQUEST:
      return {
        ...state,
        loadingswingtrips: true,
      };
    case FETCH_SWING_TRIPS_SUCCESS:
      return {
        ...state,
        loadingswingtrips: false,
        swingtrips: action.payload || [],
        errorswingtrips: "",
      };
    case FETCH_SWING_TRIPS_FAILURE:
      return {
        ...state,
        loadingswingtrips: false,
        swingtrips: [],
        errorswingtrips: action.payload,
      };
    // case STATE_CLEAN:
    //   return {
    //     ...state,
    //     paymentsMsg: "",
    //     singlerideupdatemsg: "",
    //     error: "",
    //     singlerideerror: "",
    //   };
    default:
      return state;
  }
};

export default reducer;
