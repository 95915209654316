import {
  FETCH_EXPLORE_POSTS_REQUEST,
  FETCH_EXPLORE_POSTS_SUCCESS,
  FETCH_EXPLORE_POSTS_FAILURE,
  FETCH_VIDEO_CATEGORIES_SUCCESS,
} from "./ZyppysExtendedTypes";

const initialState = {
  loadingposts: false,
  posts: [],
  errorposts: "",
  videocategories: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXPLORE_POSTS_REQUEST:
      return {
        ...state,
        loadingposts: true,
        errorposts: "",
      };
    case FETCH_EXPLORE_POSTS_SUCCESS:
      return {
        ...state,
        errorposts: "",
        loadingposts: false,
        posts: action.payload,
      };
    case FETCH_EXPLORE_POSTS_FAILURE:
      return {
        ...state,
        errorposts: action.payload,
        loadingposts: false,
        posts: [],
      };
    case FETCH_VIDEO_CATEGORIES_SUCCESS:
      return {
        ...state,
        videocategories: action.payload,
      };
    // case STATE_CLEAN:
    //   return {
    //     ...state,
    //     paymentsMsg: "",
    //     singlerideupdatemsg: "",
    //     error: "",
    //     singlerideerror: "",
    //   };
    default:
      return state;
  }
};

export default reducer;
