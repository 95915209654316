import {
  FETCH_SKIPPEDORDERS_REQUEST,
  FETCH_SKIPPEDORDERS_SUCCESS,
  FETCH_SKIPPEDORDERS_FAILURE,
  // UPDATE_AGENCY_SUCCESS,
  // UPDATE_AGENCY_FAILURE,
} from "./SkippedOTypes";

const initialState = {
  loading: false,
  skippedorders: [],
  error: "",
  // agencyUpdateMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SKIPPEDORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SKIPPEDORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        skippedorders: action.payload || [],
        error: "",
      };
    case FETCH_SKIPPEDORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        skippedorders: [],
        error: action.payload,
      };
    // case UPDATE_AGENCY_SUCCESS:
    //   return {
    //     ...state,
    //     agencyUpdateMessage: action.payload,
    //   };
    // case UPDATE_AGENCY_FAILURE:
    //   return {
    //     ...state,
    //     agencyUpdateMessage: action.payload,
    //   };
    default:
      return state;
  }
};

export default reducer;
