export const FETCH_RIDES_REQUEST = "FETCH_RIDES_REQUEST";
export const FETCH_RIDES_SUCCESS = "FETCH_RIDES_SUCCESS";
export const FETCH_RIDES_FAILURE = "FETCH_RIDES_FAILURE";
export const FETCH_SINGLE_RIDE_REQUEST = "FETCH_SINGLE_RIDE_REQUEST";
export const FETCH_SINGLE_RIDE_SUCCESS = "FETCH_SINGLE_RIDE_SUCCESS";
export const FETCH_SINGLE_RIDE_FAILURE = "FETCH_SINGLE_RIDE_FAILURE";
export const UPDATE_SINGLE_RIDE_REQUEST = "UPDATE_SINGLE_RIDE_REQUEST";
export const UPDATE_SINGLE_RIDE_SUCCESS = "UPDATE_SINGLE_RIDE_SUCCESS";
export const UPDATE_SINGLE_RIDE_FAILURE = "UPDATE_SINGLE_RIDE_FAILURE";
export const CANCEL_RIDE = "CANCEL_RIDE";
export const CANCEL_RIDE_REQUEST = "CANCEL_RIDE_REQUEST";
export const RESEND_INVOICE = "RESEND_INVOICE";
export const RESEND_INVOICE_REQUEST = "RESEND_INVOICE_REQUEST";
export const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
export const FETCH_PAYMENTS_REQUEST = "FETCH_PAYMENTS_REQUEST";
export const FETCH_PAYMENTS_FAILURE = "FETCH_PAYMENTS_FAILURE";
export const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
export const ADD_PAYMENT_REQUEST = "ADD_PAYMENT_REQUEST";
export const ADD_PAYMENT_FAILURE = "ADD_PAYMENT_FAILURE";
export const ODOMETER_READING_SUCCESS = "ODOMETER_READING_SUCCESS";
export const ODOMETER_READING_REQUEST = "ODOMETER_READING_REQUEST";
export const ODOMETER_READING_FAILURE = "ODOMETER_READING_FAILURE";
export const ADD_DRIVER_SUCCESS = "ADD_DRIVER_SUCCESS";
export const ADD_DRIVER_REQUEST = "ADD_DRIVER_REQUEST";
export const ADD_DRIVER_FAILURE = "ADD_DRIVER_FAILURE";

//
export const STATE_CLEAN = "STATE_CLEAN";
