import {
  FETCH_AGENCIES_REQUEST,
  FETCH_AGENCIES_SUCCESS,
  FETCH_AGENCIES_FAILURE,
  UPDATE_AGENCY_SUCCESS,
  UPDATE_AGENCY_FAILURE,
  FETCH_VEHICLE_AVAILABILITY_REQUEST,
  FETCH_VEHICLE_AVAILABILITY_SUCCESS,
  FETCH_VEHICLE_AVAILABILITY_FAILURE,
  STATE_CLEAN,
  UPDATE_VEHICLE_AVAILABILITY__SUCCESS,
  UPDATE_VEHICLE_AVAILABILITY__FAILURE,
  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE,
} from "./AgenciesTypes";

const initialState = {
  loading: false,
  agencies: [],
  pricing: [],
  loadingpricing: false,
  errorpricing: "",
  vehicleavailability: [],
  vehicleavailabilityerror: "",
  vehicleavailabilityloading: false,
  error: "",
  agencyUpdateMessage: "",
  vehicleavailabilitymessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AGENCIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_AGENCIES_SUCCESS:
      return {
        ...state,
        loading: false,
        agencies: action.payload,
        error: "",
      };
    case FETCH_AGENCIES_FAILURE:
      return {
        ...state,
        loading: false,
        agencies: [],
        error: action.payload,
      };
    case UPDATE_AGENCY_SUCCESS:
      return {
        ...state,
        agencyUpdateMessage: action.payload,
      };
    case UPDATE_AGENCY_FAILURE:
      return {
        ...state,
        agencyUpdateMessage: action.payload,
      };
    case FETCH_VEHICLE_AVAILABILITY_REQUEST:
      return {
        ...state,
        vehicleavailabilityloading: true,
        vehicleavailabilitymessage: "",
      };
    case FETCH_VEHICLE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        vehicleavailabilityloading: false,
        vehicleavailability: action.payload || [],
        vehicleavailabilityerror: "",
      };
    case FETCH_VEHICLE_AVAILABILITY_FAILURE:
      return {
        ...state,
        vehicleavailabilityloading: false,
        vehicleavailability: [],
        vehicleavailabilityerror: action.payload,
      };
    case UPDATE_VEHICLE_AVAILABILITY__SUCCESS:
      return {
        ...state,
        vehicleavailabilitymessage: action.payload,
      };
    case UPDATE_VEHICLE_AVAILABILITY__FAILURE:
      return {
        ...state,
        vehicleavailabilitymessage: action.payload,
      };
    case FETCH_PRICING_REQUEST:
      return {
        ...state,
        loadingpricing: true,
        errorpricing: "",
      };
    case FETCH_PRICING_SUCCESS:
      return {
        ...state,
        loadingpricing: false,
        pricing: action.payload || [],
        errorpricing: "",
      };
    case FETCH_PRICING_FAILURE:
      return {
        ...state,
        loadingpricing: false,
        errorpricing: action.payload,
        pricing: [],
      };
    // case STATE_CLEAN:
    //   return {
    //     ...state,
    //     paymentsMsg: "",
    //     singlerideupdatemsg: "",
    //     error: "",
    //     singlerideerror: "",
    //   };
    default:
      return state;
  }
};

export default reducer;
